<template>
<v-app>
    <v-dialog v-model="dialogShipmentLogResponse" width="600">
        <v-card>
            <v-card-title class="text-h5">Response</v-card-title>
            <v-card-text>
            <p></p>
            <p>{{ responseShipmentLog }}</p>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="dialogShipmentLogResponse = false"
            >
                Cerrar
            </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-app>
</template>

<script>
export default {
    data(){
        return {
            dialogShipmentLogResponse: false,
            responseShipmentLog: undefined,
        }
    },
    created(){
        
        this.$parent.$on('openDialogShipmentLogResponse', (data) => {
            this.responseShipmentLog = data.responseShipmentLog;
            this.dialogShipmentLogResponse = true; 
        });
    }
}
</script>