<template>
<v-app>
    <v-dialog v-model="dialogShipmentLogRequest" width="600">
        <v-card>
            <v-card-title class="text-h5">Request</v-card-title>
            <v-card-text>
            <p></p>
            <p>{{ requestShipmentLog }}</p>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="dialogShipmentLogRequest = false"
            >
                Cerrar
            </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-app>
</template>

<script>
export default {
    data(){
        return {
            dialogShipmentLogRequest: false,
            requestShipmentLog: undefined,
        }
    },
    created(){
        
        this.$parent.$on('openDialogShipmentLogRequest', (data) => {
            this.requestShipmentLog = data.requestShipmentLog;
            this.dialogShipmentLogRequest = true; 
        });
    }
}
</script>